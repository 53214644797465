.snake-body {
    background-color: #0468d7;
}
.food {
    background-color: #ff0000;
}
.food-package {
    background-image: url("./textures/food_package.png");
}
.bomb {
    background-image: url("./textures/bomb.png");
}
.candy {
    background-image: url("./textures/candy.png");
}
.snickers {
    background-image: url("./textures/snickers.png");
}
.ghost {
    background-image: url("./textures/ghost_right.png");
}
.ghost-gray {
    background-image: url("./textures/ghost_gray_left.png");
}

.wall {
    background-color: #292929;
}
