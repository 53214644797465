@import "./vars.less";
@import "./items.less";
@import "./dialog.less";
@import "./homepage.less";
@import "./shop.less";

@board-height: 20%;
@game-height: 100% - @board-height;

body {
    margin: 0;
    padding: 0;
}

#root {
    margin: 20px;
    padding: 0;
    * {
        font-family: @font;
    }
    .main-container {
        width: @main-width;
        height: @main-height;
        margin: 0 auto;
        .bottom-button {
            float: right;
            width: 60px;
            height: 28px;
            margin-top: 10px;
            margin-left: 10px;
            padding: 0;
            background-color: @theme-color;
            border-color: transparent;
            font-size: 11pt;
        }
        .tip-message {
            color: #2C3E50;
            font-size: 12pt;
            display: inline-block;
            margin-top: 10px;
        }
        .board-container {
            width: 100%;
            height: @board-height;
            background-color: @theme-color;
            border-top-left-radius: @main-border-radius;
            border-top-right-radius: @main-border-radius;
            ul {
                width: 100%;
                height: 100%;
                padding: 0;
                li {
                    @padding-length: 30px;
                    text-align: center;
                    color: #fff;
                    list-style: none;
                    float: left;
                    padding: @padding-length;
                    padding-bottom: (@padding-length / 2) + 12;
                    width: @main-width / 2;
                    height: 126px;
                    transition: background-color .1s;
                    p {
                        font-weight: bold;
                    }
                    &:hover {
                        background-color: #345474;
                    }
                }
            }
        }
        .game-container {
            @border-width: 1px;
            position: relative;
            height: 500px;
            width: 100%;
            border: @border-width solid @theme-color;
            border-top: none;
            background-color: #fff;
            * {
                position: absolute;
                display: inline-block;
                width: @ingame-block-width;
                height: @ingame-block-height;
                -ms-interpolation-mode: nearest-neighbor;
                    image-rendering: -moz-crisp-edges;
                    image-rendering: pixelated;
                background-size: 10px 10px;
            }
        }
    }
    .inventory-container {
        position: absolute;
        background-color: @theme-color;
        color: #fff;
        width: 300px;
        height: 630px;
        top: 20px;
        right: 30px;
        padding: 15px;
        border-radius: @main-border-radius;
        .slots {
            background-color: #fff;
            cursor: not-allowed;
            .slot {
                width: 50px;
                height: 50px;
                background-size: 100% 100%;
                background-repeat: no-repeat;
                -ms-interpolation-mode: nearest-neighbor;
                    image-rendering: -moz-crisp-edges;
                    image-rendering: pixelated;
                display: inline-block;
                cursor: pointer;
                span#amount {
                    color: #646464;
                    font-size: 10pt;
                    vertical-align: top;
                }
            }
        }
        .armor-slots .slots {
            width: 4 * 50px;
            height: 50px;
        }
        .item-slots {
            margin-top: 20px;
            .slots {
                width: 5 * 50px;
                height: 9 * 50px;
                overflow-y: auto;
                .slot {
                    border: 2px solid #fff;
                }
            }
        }
    }
}
