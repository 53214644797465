@import "./vars.less";

@msgbox-width: 455px;
@msgbox-height: 480px;

@header-height: 50%;
@content-height: 100% - @header-height;

.msgbox-container {
    display: none;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: @msgbox-width;
    height: @msgbox-height;
    background-color: @color-light-gray;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, .3) 0 1px 2px 0, rgba(0, 0, 0, .15) 0 1px 3px 1px;
    .msgbox-header {
        height: @header-height;
        img {
            margin-top: 50px;
            width: 300px;
            height: auto;
            -ms-interpolation-mode: nearest-neighbor;
                image-rendering: -moz-crisp-edges;
                image-rendering: pixelated;
        }
        h1 {
            color: #2C3E50;
            font-size: 23pt;
        }
    }
    .msgbox-contents {
        height: @content-height;
        p {
            font-size: 12pt;
            margin: 0;
            margin-bottom: 10px;
        }
        button {
            cursor: pointer;
            width: 115px;
            height: 39px;
            margin-left: 10px;
            -webkit-margin-before: 1em;
                    margin-block-start: 1em;
            -webkit-margin-after: 1em;
                    margin-block-end: 1em;
            padding-bottom: 3px;
            outline: none;
            border: 0;
            border-radius: 2px;
            background-color: @theme-color-light-1;
            color: #fff;
            font-size: 11pt;
            &:hover {
                background-color: @theme-color-light-2;
            }
            &:active {
                background-color: @theme-color-light-3;
            }
        }
    }
}
