.dialog-page {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: width .3s;
    overflow-y: auto;
    overflow-x: hidden;
    color: #2C3E50;
    .contents {
        margin-top: 24px;
    }
    &::-webkit-scrollbar {
        width: 4px;
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: @theme-color-light-1;
    }
    & > * {
        min-width: 300px;
        margin-left: 60px;
        a {
            color: #0468d7;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
        code {
            font-family: Consolas !important;
            font-size: 10pt;
        }
    }
    h2, h4 {
        margin-top: 30px;
    }
    & > a {
        text-decoration: none;
    }
    p {
        margin-top: 10px;
    }
    .switcher {
        width: 80px;
        height: 25px;
        font-size: 11pt;
        padding: 0;
        margin-left: 10px;
        background-color: @theme-color-light-2;
        border-color: transparent;
        border-radius: 3px;
    }
}

.dialog-page#about code {
    font-size: 11pt;
}
